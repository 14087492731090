<template>
  <div>
    <div class="top">
      <img :src="require('@/assets/images/echarts/logo.png')" width="100%" />
    </div>
    <div class="content">
      <ul class="menus">
        <li @click="gotoOpen('outDoorEcharts')">店铺三包情况分析</li>
        <li @click="gotoOpen('outDoorEcharts')">店铺三包同比情况分析</li>
        <li @click="gotoOpen('outDoorEcharts')">周评分覆盖率分析</li>
        <li @click="gotoOpen('teamTargetEcharts')">中队指标完成率分析</li>
        <li @click="gotoOpen('teamPhotoEcharts')">中队占道审批完成率分析</li>
        <li @click="gotoOpen('teamPhotoEcharts')">中队随手拍线索审核数分析</li>
        <li @click="gotoOpen('teamTargetEcharts')">中队劝导数量分析</li>
        <li>总体情况分析</li>
      </ul>
    </div>
    <total />
    <div class="bottom">
      <img
        :src="require('@/assets/images/echarts/outDoor/bottom.png')"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
  import { Emas } from '@/assets/js/Emas'
  import total from '@/views/echarts/total'
  import store from '@/store'

  export default {
    name: 'Index',
    components: { total },
    data() {
      return {}
    },
    created() {
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(staffName, userId, 'index', '城管报表首页', 'zzdcg.yy.gov.cn/index')
      this.fetchData()
    },

    methods: {
      //获取待办事项
      async fetchData() {},

      gotoOpen(url) {
        this.$router.push({
          path: url,
        })
      },
    },
  }
</script>

<style scoped>
  div,
  ul,
  li {
    margin: 0px;
    padding: 0px;
  }
  body {
    margin: 0px;
    padding: 0px;
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 11px 11px;
  }
  .content {
    width: 80%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .menus li:nth-last-child(1) {
    background: #07585d;
  }
  .wkuang {
    border: 2px #003a3e solid;
    border-radius: 15px;
    padding: 5px;
    margin-top: 6px;
  }
  .nkuang {
    border: 1.2px #888888 dashed;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
  }
  .nkuang span {
    color: #f20000;
  }
</style>
