<template>
  <div class="content">
    <div class="wkuang">
      <div class="nkuang">
        {{ totalOfRoad }}
        <br />
        {{ totalOfType }}
      </div>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import { getTotal } from '@/api/echarts/total/total'

  export default {
    name: 'CompleteRate',
    data() {
      return {
        totalOfRoad: '',
        totalOfType: '',
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })

        const { success, data, msg } = await getTotal({})
        if (success) {
          this.totalOfRoad = '1.' + data.TotalOfRoad
          this.totalOfType = '2.' + data.TotalOfType
        } else {
          Toast.fail(msg)
        }

        Toast.clear()
      },
    },
  }
</script>

<style scoped>
  div,
  ul,
  li {
    margin: 0px;
    padding: 0px;
  }
  body {
    margin: 0px;
    padding: 0px;
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 11px 11px;
  }
  .content {
    width: 80%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .menus li:nth-last-child(1) {
    background: #07585d;
  }
  .wkuang {
    border: 2px #003a3e solid;
    border-radius: 15px;
    padding: 5px;
    margin-top: 6px;
  }
  .nkuang {
    border: 1.2px #888888 dashed;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
  }
  .nkuang span {
    color: #f20000;
  }
</style>
